var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full mt-10 mb-10"},[_c('Accordion',{key:"step-1.2",attrs:{"open":_vm.open,"disable":!_vm.open,"header":"1.2 Reporte de estadísticas de quejas finalizadas"}},[_c('BaseRadio',{ref:"ar_type_12",staticClass:"col-span-2 mb-10",attrs:{"name":"ar_type_12","label":"¿El DCF finalizó quejas durante el período reportado? (tenga en cuenta todas las quejas terminadas por rectificación, desistimiento, inadmisión y decisión emitida por el DCF)","radioOptions":_vm.options.oYesOrNot,"required":"true","disabled":_vm.soloPreview},on:{"onChangeValueGetValue":(value) => _vm.onChangeRadio(value, 'acc_12')},model:{value:(_vm.mainData.step_1_2.DCF_finalizo_quejas_periodo_reportado),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_2, "DCF_finalizo_quejas_periodo_reportado", $$v)},expression:"mainData.step_1_2.DCF_finalizo_quejas_periodo_reportado"}}),(_vm.mainData.step_1_2.DCF_finalizo_quejas_periodo_reportado == 1)?_c('div',[_c('div',{staticClass:"w-full mt-10 mb-10"},[_c('Accordion',{key:"step-1.2.1",attrs:{"open":true,"disable":false,"header":"1.2.1 Quejas terminadas por rectificación por parte de la entidad"}},[_c('BaseRadio',{ref:"step_121",staticClass:"col-span-2 mb-10",attrs:{"name":"step_121","label":"¿Se tienen quejas terminadas por rectificación?","radioOptions":_vm.options.oYesOrNot,"required":"true","disabled":_vm.soloPreview},on:{"onChangeValueGetValue":(value) => _vm.onChangeRadio(value, 'step_121')},model:{value:(_vm.mainData.step_1_2._1_2_1_quejas_terminadas_rectificacion),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_2, "_1_2_1_quejas_terminadas_rectificacion", $$v)},expression:"mainData.step_1_2._1_2_1_quejas_terminadas_rectificacion"}}),(
              _vm.mainData.step_1_2._1_2_1_quejas_terminadas_rectificacion == 1
            )?_c('div',{staticClass:"mt-10"},[_c('ProductReasonsWithTableField',{ref:"pr_table_1_2_1_1",attrs:{"name":"pr_table_1_2_1_1","company_id":_vm.mainData.company.company_id,"labelProductCounter":'Número de quejas terminadas por rectificación según producto seleccionado',"labelReasonsCounter":'Número de quejas terminadas por rectificación según motivo seleccionado',"labelTotalCounter":'1.2.1.1. Número total de quejas terminadas por rectificación de la entidad vigilada antes de la decisión del DCF',"urlGetCounter":'?insta_recepcion_id=3&rectificacion_queja_id=1',"labelInstanceCounter":'Número de quejas registradas por terminación por rectificación de la entidad vigilada antes de la decisión del DCF',"instance-counter":_vm.mainData.step_1_2._1_2_1_1_instance_counter,"total-counter":_vm.mainData.step_1_2
                  ._1_2_1_1_total_quejas_terminadas_por_rectificacion_entidad_vigilada_antes_decision_DCF,"products_reasons":_vm.mainData.step_1_2._1_2_1_1_producto_motivo,"soloPreview":_vm.soloPreview},on:{"update:instanceCounter":function($event){return _vm.$set(_vm.mainData.step_1_2, "_1_2_1_1_instance_counter", $event)},"update:instance-counter":function($event){return _vm.$set(_vm.mainData.step_1_2, "_1_2_1_1_instance_counter", $event)},"update:totalCounter":function($event){return _vm.$set(_vm.mainData.step_1_2
                  , "_1_2_1_1_total_quejas_terminadas_por_rectificacion_entidad_vigilada_antes_decision_DCF", $event)},"update:total-counter":function($event){return _vm.$set(_vm.mainData.step_1_2
                  , "_1_2_1_1_total_quejas_terminadas_por_rectificacion_entidad_vigilada_antes_decision_DCF", $event)},"update:products_reasons":function($event){return _vm.$set(_vm.mainData.step_1_2, "_1_2_1_1_producto_motivo", $event)}}}),_c('BaseTextarea',{ref:"describe_1.2.1.3",staticClass:"col-start-1 col-span-2",attrs:{"id":"describe_1.2.1.3","label":"1.2.1.1. Presente y describa las situaciones, productos y tipologías de quejas en las cuales la entidad más rectifica","placeholder":"","maxlength":"1000","disabled":_vm.soloPreview},model:{value:(
                _vm.mainData.step_1_2
                  ._1_2_1_1_resuma_situaciones_rectificación_entidad
              ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_2
                  , "_1_2_1_1_resuma_situaciones_rectificación_entidad", $$v)},expression:"\n                mainData.step_1_2\n                  ._1_2_1_1_resuma_situaciones_rectificación_entidad\n              "}}),_c('ProductReasonsWithTableField',{ref:"pr_table_1_2_1_2",attrs:{"name":"pr_table_1_2_1_2","company_id":_vm.mainData.company.company_id,"labelProductCounter":'Número de quejas terminadas por rectificación según producto seleccionado',"labelReasonsCounter":'Número de quejas terminadas por rectificación según motivo seleccionado',"labelTotalCounter":'1.2.1.2. Número total de quejas terminadas por rectificación de la entidad vigilada después de la decisión del DCF',"labelInstanceCounter":'Número de quejas registradas por terminación por rectificación de la entidad vigilada después de la decisión del DCF',"urlGetCounter":'?insta_recepcion_id=3&rectificacion_queja_id=1',"instance-counter":_vm.mainData.step_1_2._1_2_1_2_instance_counter,"total-counter":_vm.mainData.step_1_2
                  ._1_2_1_2_total_quejas_terminadas_por_rectificacion_entidad_vigilada_despues_decision_DCF,"products_reasons":_vm.mainData.step_1_2._1_2_1_2_producto_motivo,"soloPreview":_vm.soloPreview},on:{"update:instanceCounter":function($event){return _vm.$set(_vm.mainData.step_1_2, "_1_2_1_2_instance_counter", $event)},"update:instance-counter":function($event){return _vm.$set(_vm.mainData.step_1_2, "_1_2_1_2_instance_counter", $event)},"update:totalCounter":function($event){return _vm.$set(_vm.mainData.step_1_2
                  , "_1_2_1_2_total_quejas_terminadas_por_rectificacion_entidad_vigilada_despues_decision_DCF", $event)},"update:total-counter":function($event){return _vm.$set(_vm.mainData.step_1_2
                  , "_1_2_1_2_total_quejas_terminadas_por_rectificacion_entidad_vigilada_despues_decision_DCF", $event)},"update:products_reasons":function($event){return _vm.$set(_vm.mainData.step_1_2, "_1_2_1_2_producto_motivo", $event)}}}),_c('BaseTextarea',{ref:"describe_1.2.1.3",staticClass:"col-start-1 col-span-2",attrs:{"id":"describe_1.2.1.3","label":"1.2.1.3. Resuma las situaciones objeto de rectificación por parte de la entidad, indicando la forma en que esta procede (priorice lo más representativo a juicio de la DCF, por impacto en los consumidores financieros o requerir acciones de mejora que ameriten el reporte a la SFC). Por favor no incluir casos ni quejas individuales.","placeholder":"","maxlength":"1000","disabled":_vm.soloPreview},model:{value:(
                _vm.mainData.step_1_2
                  ._1_2_1_3_resuma_situaciones_rectificación_entidad
              ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_2
                  , "_1_2_1_3_resuma_situaciones_rectificación_entidad", $$v)},expression:"\n                mainData.step_1_2\n                  ._1_2_1_3_resuma_situaciones_rectificación_entidad\n              "}})],1):_vm._e()],1)],1),_c('div',{staticClass:"w-full mt-10 mb-10"},[_c('Accordion',{key:"step-1.2.2",attrs:{"open":true,"disable":false,"header":"1.2.2 Quejas terminadas por desistimiento"}},[_c('BaseRadio',{ref:"step_122",staticClass:"col-span-2 mb-10",attrs:{"name":"step_122","label":"¿Se tienen quejas terminadas por desistimiento?","radioOptions":_vm.options.oYesOrNot,"required":"true","disabled":_vm.soloPreview},on:{"onChangeValueGetValue":(value) => _vm.onChangeRadio(value, 'step_122')},model:{value:(_vm.mainData.step_1_2._1_2_2_quejas_terminadas_desistimiento),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_2, "_1_2_2_quejas_terminadas_desistimiento", $$v)},expression:"mainData.step_1_2._1_2_2_quejas_terminadas_desistimiento"}}),(
              _vm.mainData.step_1_2._1_2_2_quejas_terminadas_desistimiento == 1
            )?_c('div',[_c('BaseInput',{ref:"1.2.2.1  total_quejas_terminadas_inadmisión",attrs:{"type":"number","label":"1.2.2.1 Número total de quejas terminadas por desistimiento","required":"true","disabled":_vm.soloPreview},model:{value:(
                _vm.mainData.step_1_2
                  ._1_2_2_1_total_quejas_terminadas_por_desistimiento
              ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_2
                  , "_1_2_2_1_total_quejas_terminadas_por_desistimiento", $$v)},expression:"\n                mainData.step_1_2\n                  ._1_2_2_1_total_quejas_terminadas_por_desistimiento\n              "}}),_c('BaseTextarea',{ref:"describe_1.2.2.2",staticClass:"col-start-1 col-span-2",attrs:{"id":"describe_1.2.2.2","label":"Presente y describa las situaciones, productos y tipologías de quejas en las cuales en mayor medida se presentan desistimientos por parte de los consumidores","placeholder":"","maxlength":"2000","required":"true","disabled":_vm.soloPreview},model:{value:(
                _vm.mainData.step_1_2
                  ._1_2_2_2_tipologia_indique_desistimiento_consumidores_financieros
              ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_2
                  , "_1_2_2_2_tipologia_indique_desistimiento_consumidores_financieros", $$v)},expression:"\n                mainData.step_1_2\n                  ._1_2_2_2_tipologia_indique_desistimiento_consumidores_financieros\n              "}}),_c('BaseTextarea',{ref:"describe_1.2.2.2",staticClass:"col-start-1 col-span-2",attrs:{"id":"describe_1.2.2.2","label":"1.2.2.2 Indique las razones de desistimiento informadas por los consumidores financieros, priorizando las que la DCF considere más representativas","placeholder":"","maxlength":"2000","required":"true","disabled":_vm.soloPreview},model:{value:(
                _vm.mainData.step_1_2
                  ._1_2_2_2_indique_desistimiento_consumidores_financieros
              ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_2
                  , "_1_2_2_2_indique_desistimiento_consumidores_financieros", $$v)},expression:"\n                mainData.step_1_2\n                  ._1_2_2_2_indique_desistimiento_consumidores_financieros\n              "}})],1):_vm._e()],1)],1),_c('div',{staticClass:"w-full mt-10 mb-10"},[_c('Accordion',{key:"step-1.2.3",attrs:{"open":true,"disable":false,"header":"1.2.3 Quejas terminadas por inadmisión"}},[_c('BaseRadio',{ref:"step_123",staticClass:"col-span-2 mb-10",attrs:{"name":"step_123","label":"¿Se tienen quejas terminadas por inadmisión?","radioOptions":_vm.options.oYesOrNot,"required":"true","disabled":_vm.soloPreview},on:{"onChangeValueGetValue":(value) => _vm.onChangeRadio(value, 'step_123')},model:{value:(_vm.mainData.step_1_2._1_2_3_quejas_terminadas_inadmision),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_2, "_1_2_3_quejas_terminadas_inadmision", $$v)},expression:"mainData.step_1_2._1_2_3_quejas_terminadas_inadmision"}}),(_vm.mainData.step_1_2._1_2_3_quejas_terminadas_inadmision == 1)?_c('div',[_c('div',{staticClass:"grid grid-cols-2 gap-x-8 mb-10"},[_c('BaseInput',{ref:" total_quejas_terminadas_inadmisión",attrs:{"type":"text","label":"1.2.3 Número total de quejas terminadas por inadmisión","required":true,"disabled":_vm.soloPreview},model:{value:(
                  _vm.mainData.step_1_2._1_2_3_total_quejas_terminadas_inadmision
                ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_2, "_1_2_3_total_quejas_terminadas_inadmision", $$v)},expression:"\n                  mainData.step_1_2._1_2_3_total_quejas_terminadas_inadmision\n                "}})],1),_c('BaseCheckboxVertical',{staticClass:"col-start-1 col-span-4 mb-10",attrs:{"label":"1.2.3.1 Justificación de la inadmisión por parte del DCF","value":_vm.mainData.step_1_2._1_2_3_1_justificacion_inadmisión_DCF_ids,"data":_vm.options.oJustInadmision,"disabled":_vm.soloPreview},on:{"notify-changes":_vm.onChangeSelectJustInadmision}}),_c('div',{staticClass:"col-span-2"}),(_vm.isJustInadActive('1'))?_c('a-card',{staticClass:"text-left col-span-2",attrs:{"size":"small","title":"1) Causal legal de exclusión de competencia"}},[_c('a-button',{attrs:{"slot":"extra","type":"link"},on:{"click":function($event){_vm.openAccordions.showJustInad1 = !_vm.openAccordions.showJustInad1}},slot:"extra"},[_vm._v(" Ver detalles "),_c('a-icon',{attrs:{"type":_vm.openAccordions.showJustInad1 ? 'up' : 'down'}})],1),(_vm.openAccordions.showJustInad1)?_c('div',[_c('BaseNumber',{ref:"num_12__numero_total_solicitudes",attrs:{"label":"Número total de justificación seleccionada.","placeholder":"Ej: 123","disabled":_vm.soloPreview},model:{value:(
                    _vm.mainData.step_1_2
                      ._1_2_3_1_causal_legal_numero_total_solicitudes
                  ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_2
                      , "_1_2_3_1_causal_legal_numero_total_solicitudes", $$v)},expression:"\n                    mainData.step_1_2\n                      ._1_2_3_1_causal_legal_numero_total_solicitudes\n                  "}}),_c('a-collapse',{staticClass:"mt-6"},[_c('a-collapse-panel',{key:"1",attrs:{"header":"Causales"}},[_c('p',{staticClass:"text-opacity-80 text-left",class:_vm.mainData.step_1_2
                          ._1_2_3_1_causal_legal_numero_total_solicitudes !=
                        _vm.totalCausales_1_2_1
                          ? 'text-red-500'
                          : 'text-green-500',attrs:{"slot":"extra"},slot:"extra"},[_vm._v(" Total: "+_vm._s(_vm.totalCausales_1_2_1)+" "+_vm._s(_vm.mainData.step_1_2 ._1_2_3_1_causal_legal_numero_total_solicitudes != _vm.totalCausales_1_2_1 ? "(No coinciden los totales)" : "")+" ")]),_c('a-table',{attrs:{"size":"small","pagination":false,"row-selection":_vm.rowSelection,"columns":_vm.columnsCausal,"data-source":_vm.options.oTipoJustInCausaLegal},scopedSlots:_vm._u([{key:"labelName",fn:function(text, record){return [_c('a',{staticClass:"flex text-left"},[_vm._v(_vm._s(text))])]}},{key:"count",fn:function(text, record){return [(_vm.isRowSelected(record.id))?_c('BaseNumber',{ref:"num_31_numero_total_solicitudes",staticClass:"col-start-1 col-span-2 mt-0 pt-0",attrs:{"value":_vm.valueCausal(record.id),"label":"Digita una cantidad","disabled":_vm.soloPreview,"placeholder":"Ej: 123","mt_disabled":true},on:{"notify-change":function($event){return _vm.updateDetail(record.id, $event)}}}):_vm._e()]}}],null,false,3019779980)})],1)],1),_c('BaseRadio',{ref:"opt_causal_legal_aplica_producto_motivo",staticClass:"col-start-1 col-span-2",attrs:{"label":"¿Aplica producto y motivo?","radioOptions":_vm.options.oYesOrNot,"disabled":_vm.soloPreview},model:{value:(
                    _vm.mainData.step_1_2
                      ._1_2_3_1_causal_legal_aplica_producto_motivo
                  ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_2
                      , "_1_2_3_1_causal_legal_aplica_producto_motivo", $$v)},expression:"\n                    mainData.step_1_2\n                      ._1_2_3_1_causal_legal_aplica_producto_motivo\n                  "}}),(_vm.causalLegalIncludeOtra())?_c('BaseTextarea',{ref:"describe",staticClass:"col-start-1 col-span-4",attrs:{"id":"describe","label":"Descripción","placeholder":"Mensaje","maxlength":"50","disabled":_vm.soloPreview},model:{value:(
                    _vm.mainData.step_1_2._1_2_3_1_causal_legal_otra_descripcion
                  ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_2, "_1_2_3_1_causal_legal_otra_descripcion", $$v)},expression:"\n                    mainData.step_1_2._1_2_3_1_causal_legal_otra_descripcion\n                  "}}):_vm._e(),(
                    _vm.mainData.step_1_2
                      ._1_2_3_1_causal_legal_aplica_producto_motivo == 1
                  )?_c('ProductReasonsWithTableField',{ref:"pr_table_1_2_3_1",attrs:{"name":"pr_table_1_2_3_1","company_id":_vm.mainData.company.company_id,"labelReasonsCounter":'Número de quejas terminadas por inadmisión según motivo seleccionado',"labelProductCounter":'Número de quejas terminadas por inadmisión según producto seleccionado',"products_reasons":_vm.mainData.step_1_3._1_2_3_1_producto_motivo,"hideTopInputs":true,"soloPreview":_vm.soloPreview},on:{"update:products_reasons":function($event){return _vm.$set(_vm.mainData.step_1_3, "_1_2_3_1_producto_motivo", $event)}}}):_vm._e(),(
                    _vm.mainData.step_1_2
                      ._1_2_3_1_justificacion_inadmision_causal_legal_aplica_producto_motivo
                  )?_c('BaseTextarea',{ref:"describe",staticClass:"col-start-1 col-span-4",attrs:{"id":"describe","label":"Descripción","placeholder":"Mensaje","maxlength":"1000","disabled":_vm.soloPreview},model:{value:(_vm.mainData.step_1_2._1_2_3_1_descripcion),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_2, "_1_2_3_1_descripcion", $$v)},expression:"mainData.step_1_2._1_2_3_1_descripcion"}}):_vm._e()],1):_vm._e()],1):_vm._e(),(_vm.isJustInadActive('2'))?_c('a-card',{staticClass:"text-left col-span-2",attrs:{"size":"small","title":"2) Se refiere a otra entidad vigilada"}},[_c('a-button',{attrs:{"slot":"extra","type":"link"},on:{"click":function($event){_vm.openAccordions.showJustInad2 = !_vm.openAccordions.showJustInad2}},slot:"extra"},[_vm._v(" Ver detalles "),_c('a-icon',{attrs:{"type":_vm.openAccordions.showJustInad2 ? 'up' : 'down'}})],1),(_vm.openAccordions.showJustInad2)?_c('div',[_c('BaseNumber',{ref:"num_12_numero_total_solicitudes",staticClass:"col-start-1 col-span-2",attrs:{"label":"Número total de quejas asociadas a la justificación seleccionada","placeholder":"Ej: 123","disabled":_vm.soloPreview},model:{value:(
                    _vm.mainData.step_1_2
                      ._1_2_3_1_justificacion_inadmision_otra_entidad_numero_total_solicitudes
                  ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_2
                      , "_1_2_3_1_justificacion_inadmision_otra_entidad_numero_total_solicitudes", $$v)},expression:"\n                    mainData.step_1_2\n                      ._1_2_3_1_justificacion_inadmision_otra_entidad_numero_total_solicitudes\n                  "}})],1):_vm._e()],1):_vm._e(),(_vm.isJustInadActive('3'))?_c('a-card',{staticClass:"text-left col-span-2",attrs:{"size":"small","title":"3) Solicitud incompleta, no clara, lenguaje soez o agresivo"}},[_c('a-button',{attrs:{"slot":"extra","type":"link"},on:{"click":function($event){_vm.openAccordions.showJustInad3 = !_vm.openAccordions.showJustInad3}},slot:"extra"},[_vm._v(" Ver detalles "),_c('a-icon',{attrs:{"type":_vm.openAccordions.showJustInad3 ? 'up' : 'down'}})],1),(_vm.openAccordions.showJustInad3)?_c('div',[_c('BaseNumber',{ref:"num_12_numero_total_solicitudes",staticClass:"col-start-1 col-span-2",attrs:{"label":"Número total de solicitudes inadmitidas asociadas a la justificación seleccionada.","placeholder":"Ej: 123","disabled":_vm.soloPreview},model:{value:(
                    _vm.mainData.step_1_2
                      ._1_2_3_1_justificacion_inadmision_solicitud_incompleta_numero_total_solicitudes
                  ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_2
                      , "_1_2_3_1_justificacion_inadmision_solicitud_incompleta_numero_total_solicitudes", $$v)},expression:"\n                    mainData.step_1_2\n                      ._1_2_3_1_justificacion_inadmision_solicitud_incompleta_numero_total_solicitudes\n                  "}})],1):_vm._e()],1):_vm._e(),(_vm.isJustInadActive('4'))?_c('a-card',{staticClass:"text-left col-span-2",attrs:{"size":"small","title":"4) Otra"}},[_c('a-button',{attrs:{"slot":"extra","type":"link"},on:{"click":function($event){_vm.openAccordions.showJustInad4 = !_vm.openAccordions.showJustInad4}},slot:"extra"},[_vm._v(" Ver detalles "),_c('a-icon',{attrs:{"type":_vm.openAccordions.showJustInad4 ? 'up' : 'down'}})],1),(_vm.openAccordions.showJustInad4)?_c('div',[_c('BaseNumber',{ref:"num_12_numero_total_solicitudes",staticClass:"col-start-1 col-span-2",attrs:{"label":"Número total de inadmitidas asociadas a la justificación seleccionada.","placeholder":"Ej: 123","disabled":_vm.soloPreview},model:{value:(
                    _vm.mainData.step_1_2
                      ._1_2_3_1_justificacion_inadmision_otra_numero_total_solicitudes
                  ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_2
                      , "_1_2_3_1_justificacion_inadmision_otra_numero_total_solicitudes", $$v)},expression:"\n                    mainData.step_1_2\n                      ._1_2_3_1_justificacion_inadmision_otra_numero_total_solicitudes\n                  "}})],1):_vm._e()],1):_vm._e(),_c('BaseTextarea',{ref:"describe_1.2.2.2",staticClass:"mt-10 col-start-1 col-span-2",attrs:{"id":"describe_1.2.2.2","label":"1.2.3.2 Resuma las situaciones que hayan sido objeto de inadmisión por parte de la DCF, priorizando las más representativas. No incluir casos ni situaciones particulares","placeholder":"","maxlength":"2000","disabled":_vm.soloPreview},model:{value:(
                _vm.mainData.step_1_2._1_2_3_2_resuma_situaciones_inadmisión_DCF
              ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_2, "_1_2_3_2_resuma_situaciones_inadmisión_DCF", $$v)},expression:"\n                mainData.step_1_2._1_2_3_2_resuma_situaciones_inadmisión_DCF\n              "}})],1):_vm._e()],1)],1),_c('div',{staticClass:"w-full mt-10 mb-10"},[_c('Accordion',{key:"step-1.2.4",staticClass:"w-full",attrs:{"open":true,"disable":false,"header":"1.2.4 Quejas terminadas por decisión emitida por el DCF"}},[_c('BaseRadio',{ref:"step_124",staticClass:"col-span-2 mb-10",attrs:{"name":"step_124","label":"¿Se tienen quejas terminadas por decisión emitida por el DCF?","radioOptions":_vm.options.oYesOrNot,"required":"true","disabled":_vm.soloPreview},on:{"onChangeValueGetValue":(value) => _vm.onChangeRadio(value, 'step_124')},model:{value:(_vm.mainData.step_1_2._1_2_4_quejas_terminadas_emitida_DCF),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_2, "_1_2_4_quejas_terminadas_emitida_DCF", $$v)},expression:"mainData.step_1_2._1_2_4_quejas_terminadas_emitida_DCF"}}),(_vm.mainData.step_1_2._1_2_4_quejas_terminadas_emitida_DCF == 1)?_c('div',[_c('ProductReasonsWithTableField',{ref:"pr_table_1_2_4_1",attrs:{"name":"pr_table_1_2_4_1","company_id":_vm.mainData.company.company_id,"labelReasonsCounter":'Número de quejas terminadas por decisión emitida por el DCF según motivo seleccionado',"labelProductCounter":'Número de quejas terminadas por decisión emitida por el DCF según producto seleccionado',"labelTotalCounter":'1.2.4.1 Número total de quejas terminadas por decisión emitida por el DCF',"labelInstanceCounter":'Número de quejas registradas terminadas por desistimiento',"instance-counter":_vm.mainData.step_1_2._1_2_4_1_instance_counter,"total-counter":_vm.mainData.step_1_2
                  ._1_2_4_1_total_quejas_terminadas_decision_emitida_DCF,"products_reasons":_vm.mainData.step_1_2._1_2_4_1_producto_motivo,"urlGetCounter":'?insta_recepcion_id=3&admision_id=2',"hideInstanceCounter":true,"soloPreview":_vm.soloPreview},on:{"update:instanceCounter":function($event){return _vm.$set(_vm.mainData.step_1_2, "_1_2_4_1_instance_counter", $event)},"update:instance-counter":function($event){return _vm.$set(_vm.mainData.step_1_2, "_1_2_4_1_instance_counter", $event)},"update:totalCounter":function($event){return _vm.$set(_vm.mainData.step_1_2
                  , "_1_2_4_1_total_quejas_terminadas_decision_emitida_DCF", $event)},"update:total-counter":function($event){return _vm.$set(_vm.mainData.step_1_2
                  , "_1_2_4_1_total_quejas_terminadas_decision_emitida_DCF", $event)},"update:products_reasons":function($event){return _vm.$set(_vm.mainData.step_1_2, "_1_2_4_1_producto_motivo", $event)}}}),_c('h2',{staticClass:"mt-20 text-[#5C5C5C] text-lg font-semibold text-left"},[_vm._v(" 1.2.5 Decisiones del DCF emitidas a favor de la entidad ")]),_c('BaseRadio',{ref:"step_125",staticClass:"mt-20 mb-10",attrs:{"name":"step_125","label":"","radioOptions":_vm.options.doOrDoNot,"required":"true","disabled":_vm.soloPreview},on:{"onChangeValueGetValue":(value) => _vm.onChangeRadio(value, 'step_125')},model:{value:(
                _vm.mainData.step_1_2._1_2_5_decisiones_DCF_emitidas_favor_entidad
              ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_2, "_1_2_5_decisiones_DCF_emitidas_favor_entidad", $$v)},expression:"\n                mainData.step_1_2._1_2_5_decisiones_DCF_emitidas_favor_entidad\n              "}}),(
                _vm.mainData.step_1_2
                  ._1_2_5_decisiones_DCF_emitidas_favor_entidad == 1
              )?_c('ProductReasonsWithTableField',{ref:"pr_table_1_2_5",attrs:{"name":"pr_table_1_2_5","company_id":_vm.mainData.company.company_id,"labelReasonsCounter":'Número de quejas terminadas por decisión emitida por el DCF a favor de la entidad según motivo seleccionado',"labelProductCounter":'Número de quejas terminadas por decisión emitida por el DCF a favor de la entidad según producto seleccionado',"labelTotalCounter":'Número total de decisiones emitidas a favor de la entidad',"labelInstanceCounter":'Número total registrado de decisiones emitidas a favor de la entidad',"instance-counter":_vm.mainData.step_1_2._1_2_5_instance_counter,"total-counter":_vm.mainData.step_1_2._1_2_5_total_decision_emitida_favor_entidad,"products_reasons":_vm.mainData.step_1_2._1_2_5_producto_motivo,"urlGetCounter":'?insta_recepcion_id=3&a_favor_de_id=3',"hideInstanceCounter":true,"soloPreview":_vm.soloPreview},on:{"update:instanceCounter":function($event){return _vm.$set(_vm.mainData.step_1_2, "_1_2_5_instance_counter", $event)},"update:instance-counter":function($event){return _vm.$set(_vm.mainData.step_1_2, "_1_2_5_instance_counter", $event)},"update:totalCounter":function($event){return _vm.$set(_vm.mainData.step_1_2, "_1_2_5_total_decision_emitida_favor_entidad", $event)},"update:total-counter":function($event){return _vm.$set(_vm.mainData.step_1_2, "_1_2_5_total_decision_emitida_favor_entidad", $event)},"update:products_reasons":function($event){return _vm.$set(_vm.mainData.step_1_2, "_1_2_5_producto_motivo", $event)}}}):_vm._e(),(_vm.showStep.step_125)?_c('BaseTextarea',{ref:"describe_1.2.5",staticClass:"mt-10",attrs:{"id":"describe_1.2.5","label":"Resuma las situaciones objeto de decisión a favor de la entidad, destacando aquellas buenas prácticas que la defensoría haya identificado en el proceder de la vigilada. Sin hacer referencia a casos particulares","placeholder":"","maxlength":"1000","disabled":_vm.soloPreview},model:{value:(
                _vm.mainData.step_1_2
                  ._1_2_5_resuma_situaciones_decisión_favor_entidad
              ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_2
                  , "_1_2_5_resuma_situaciones_decisión_favor_entidad", $$v)},expression:"\n                mainData.step_1_2\n                  ._1_2_5_resuma_situaciones_decisión_favor_entidad\n              "}}):_vm._e(),_c('div',{staticClass:"w-full"},[_c('h2',{staticClass:"mt-20 text-[#5C5C5C] text-lg font-semibold text-left"},[_vm._v(" 1.2.5.1 Decisiones emitidas a favor del consumidor financiero (total y parcialmente favorables) ")])]),_c('BaseRadioConditional',{ref:"step_1251",staticClass:"mt-20 mb-10",attrs:{"name":"step_1251","label":"Decisiones del DCF\n            totalmente favorables al consumidor financiero","radioOptions":_vm.options.TotalF,"required":"true"},on:{"onChangeValueGetValue":(value) => _vm.onChangeRadio(value, 'step_1251')},model:{value:(
                _vm.mainData.step_1_2
                  ._1_2_5_1_decisiones_DCF_totalmente_favorables_consumidor_financiero
              ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_2
                  , "_1_2_5_1_decisiones_DCF_totalmente_favorables_consumidor_financiero", $$v)},expression:"\n                mainData.step_1_2\n                  ._1_2_5_1_decisiones_DCF_totalmente_favorables_consumidor_financiero\n              "}}),(
                _vm.mainData.step_1_2
                  ._1_2_5_1_decisiones_DCF_totalmente_favorables_consumidor_financiero ==
                1
              )?_c('ProductReasonsWithTableField',{ref:"pr_table_1_2_5_1",attrs:{"name":"pr_table_1_2_5_1","company_id":_vm.mainData.company.company_id,"labelReasonsCounter":'Número total de decisiones totalmente favorables al consumidor financiero según motivo seleccionado',"labelProductCounter":'Número total de decisiones totalmente favorables al consumidor financiero según producto seleccionado',"labelTotalCounter":'Número total de decisiones totalmente favorables al consumidor financiero',"labelInstanceCounter":'Número total registrado de decisiones totalmente favorables al consumidor financiero',"instance-counter":_vm.mainData.step_1_2._1_2_5_1_instance_counter,"total-counter":_vm.mainData.step_1_2
                  ._1_2_5_1_total_decision_totalmente_favorables_consumidor_financiero,"products_reasons":_vm.mainData.step_1_2._1_2_5_1_producto_motivo,"urlGetCounter":'?insta_recepcion_id=3&a_favor_de_id=1',"hideInstanceCounter":true,"soloPreview":_vm.soloPreview},on:{"update:instanceCounter":function($event){return _vm.$set(_vm.mainData.step_1_2, "_1_2_5_1_instance_counter", $event)},"update:instance-counter":function($event){return _vm.$set(_vm.mainData.step_1_2, "_1_2_5_1_instance_counter", $event)},"update:totalCounter":function($event){return _vm.$set(_vm.mainData.step_1_2
                  , "_1_2_5_1_total_decision_totalmente_favorables_consumidor_financiero", $event)},"update:total-counter":function($event){return _vm.$set(_vm.mainData.step_1_2
                  , "_1_2_5_1_total_decision_totalmente_favorables_consumidor_financiero", $event)},"update:products_reasons":function($event){return _vm.$set(_vm.mainData.step_1_2, "_1_2_5_1_producto_motivo", $event)}}}):_vm._e(),(_vm.showStep.step_1251)?_c('BaseTextarea',{ref:"describe_1.2.51",staticClass:"mt-10",attrs:{"id":"describe_1.2.51","label":"Resuma la forma en que la entidad procede frente a las situaciones objeto de decisión totalmente a favor del consumidor financiero (priorice lo más representativo por impacto en los consumidores o que requiera acciones de mejora). Por favor no incluir casos ni quejas individuales.","placeholder":"","maxlength":"1000","disabled":_vm.soloPreview},model:{value:(
                _vm.mainData.step_1_2
                  ._1_2_5_1_resuma_situaciones_decision_totalmente_favor_consumidor_financiero
              ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_2
                  , "_1_2_5_1_resuma_situaciones_decision_totalmente_favor_consumidor_financiero", $$v)},expression:"\n                mainData.step_1_2\n                  ._1_2_5_1_resuma_situaciones_decision_totalmente_favor_consumidor_financiero\n              "}}):_vm._e(),_c('BaseRadioConditional',{ref:"step_1252",staticClass:"mt-20 mb-10",attrs:{"name":"step_1252","label":"1.2.5.2 Decisiones del DCF parcialmente favorables al consumidor financiero","radioOptions":_vm.options.ParcialF,"required":"true"},on:{"onChangeValueGetValue":(value) => _vm.onChangeRadio(value, 'step_1252')},model:{value:(
                _vm.mainData.step_1_2
                  ._1_2_5_2_decisiones_DCF_parcialmente_favorables_consumidor_financiero
              ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_2
                  , "_1_2_5_2_decisiones_DCF_parcialmente_favorables_consumidor_financiero", $$v)},expression:"\n                mainData.step_1_2\n                  ._1_2_5_2_decisiones_DCF_parcialmente_favorables_consumidor_financiero\n              "}}),(
                _vm.mainData.step_1_2
                  ._1_2_5_2_decisiones_DCF_parcialmente_favorables_consumidor_financiero ==
                1
              )?_c('ProductReasonsWithTableField',{ref:"pr_table_1_2_5_2",attrs:{"name":"pr_table_1_2_5_2","company_id":_vm.mainData.company.company_id,"labelReasonsCounter":'Número total de decisiones parcialmente favorables según motivo seleccionado',"labelProductCounter":'Número total de decisiones parcialmente favorables según producto seleccionado',"labelTotalCounter":'Número total de decisiones parcialmente favorables al consumidor financiero',"labelInstanceCounter":'Número total de decisiones parcialmente favorables registradas',"instance-counter":_vm.mainData.step_1_2._1_2_5_2_instance_counter,"total-counter":_vm.mainData.step_1_2
                  ._1_2_5_2_total_decision_pacialmente_favorables_consumidor_financiero,"products_reasons":_vm.mainData.step_1_2._1_2_5_2_producto_motivo,"urlGetCounter":'?insta_recepcion_id=3&a_favor_de_id=2',"hideInstanceCounter":true,"soloPreview":_vm.soloPreview},on:{"update:instanceCounter":function($event){return _vm.$set(_vm.mainData.step_1_2, "_1_2_5_2_instance_counter", $event)},"update:instance-counter":function($event){return _vm.$set(_vm.mainData.step_1_2, "_1_2_5_2_instance_counter", $event)},"update:totalCounter":function($event){return _vm.$set(_vm.mainData.step_1_2
                  , "_1_2_5_2_total_decision_pacialmente_favorables_consumidor_financiero", $event)},"update:total-counter":function($event){return _vm.$set(_vm.mainData.step_1_2
                  , "_1_2_5_2_total_decision_pacialmente_favorables_consumidor_financiero", $event)},"update:products_reasons":function($event){return _vm.$set(_vm.mainData.step_1_2, "_1_2_5_2_producto_motivo", $event)}}}):_vm._e(),(
                _vm.mainData.step_1_2
                  ._1_2_5_2_decisiones_DCF_parcialmente_favorables_consumidor_financiero ==
                1
              )?_c('BaseTextarea',{ref:"describe_1.2.52",staticClass:"mt-10",attrs:{"id":"describe_1.2.52","label":"Resuma la forma en que la entidad procede frente a las situaciones objeto de decisión parcialmente a favor del consumidor financiero (priorice lo más representativo por impacto en los consumidores, o que requiera acciones de mejora). Por favor no incluir casos ni quejas individuales","placeholder":"","maxlength":"1000","disabled":_vm.soloPreview},model:{value:(
                _vm.mainData.step_1_2
                  ._1_2_5_2_resuma_situaciones_decision_pacialmente_favorrable_consumidor_financiero
              ),callback:function ($$v) {_vm.$set(_vm.mainData.step_1_2
                  , "_1_2_5_2_resuma_situaciones_decision_pacialmente_favorrable_consumidor_financiero", $$v)},expression:"\n                mainData.step_1_2\n                  ._1_2_5_2_resuma_situaciones_decision_pacialmente_favorrable_consumidor_financiero\n              "}}):_vm._e()],1):_vm._e()],1)],1)]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }