<script>
const columns = [
  {
    title: "Nombre motivo",
    dataIndex: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "Total quejas",
    dataIndex: "count",
    scopedSlots: { customRender: "count" },
  },
];

export default {
  name: "TableWithCountField",
  props: {
    soloPreview: {
      type: Boolean,
      default: false,
    },
    totalCounterModel: {
      type: Number,
      default: -1,
    },
    options: {
      type: Array,
      default: () => [],
    },
    selectedItemsIds: {
      type: Array,
      default: () => [],
    },
    selectedItemsDetail: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    columns: columns,
    localOptions: [],
  }),
  watch: {
    options(newVal) {
      // console.log('newVale', newVal)
      if (newVal) this.localOptions = newVal;
    },
  },
  created() {
    this.localOptions = this.options;
  },
  methods: {
    isRowSelected(key) {
      return this.selectedItemsIds.includes(key);
    },
    valueCount(id) {
      // Obtener la cantidad del input del elemento/row
      const item = this.selectedItemsDetail.find(
        (element) => element.id === id
      );

      if (item) {
        return item.count;
      } else {
        console.error("No se encontró un elemento con el id:", id);
        return null;
      }
    },
    updateDetail(key, value) {
      // Guarda la cantidad digitada
      console.log(key, value);

      const detailIndex = this.selectedItemsDetail.findIndex(
        (detail) => detail.id === key
      );
      if (detailIndex >= 0) {
        this.selectedItemsDetail[detailIndex].count = value;
      } else {
        this.selectedItemsDetail.push({ id: key, count: value });
      }
      this.$emit(
        "update-items",
        this.selectedItemsIds,
        this.selectedItemsDetail
      );
    },
  },

  computed: {
    totalItems() {
      return this.selectedItemsDetail.reduce((sum, item) => {
        return sum + Number(item.count);
      }, 0);
    },
    rowSelection() {
      const selectedRowKeys = this.localOptions
        .map((obj, index) =>
          this.selectedItemsIds.includes(obj.id) ? index : null
        )
        .filter((index) => index !== null);
      return {
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows, event) => {
          this.selectedItemsIds = [];
          selectedRows.forEach((element) => {
            this.selectedItemsIds.push(element.id);
          });

          const updatedDetail = [];

          this.localOptions.forEach((element) => {
            if (this.selectedItemsIds.includes(element.id)) {
              const justDetail = this.selectedItemsDetail.find(
                (detail) => detail.id === element.id
              );

              updatedDetail.push({
                id: element.id,
                name: element.name,
                count: justDetail?.count ?? null,
              });
            }
          });

          this.selectedItemsDetail = updatedDetail;

          this.$emit(
            "update-items",
            this.selectedItemsIds,
            this.selectedItemsDetail
          );
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: this.soloPreview,
            name: record.name,
            defaultChecked: record.isChecked ?? false,
          },
        }),
      };
    },
  },
};
</script>

<template>
  <div class="w-full">
    <div class="w-100 mb-6">
      <a-collapse class="mt-6 text-left">
        <a-collapse-panel
          key="1"
          :header="`Motivos (${selectedItemsIds.length} motivos seleccionados)`"
        >
          <p
            slot="extra"
            class="text-opacity-80"
            :class="
              totalCounterModel != totalItems
                ? 'text-red-500'
                : 'text-green-500'
            "
          >
            Total: {{ totalItems }}
            {{
              totalCounterModel != totalItems
                ? " (El valor no coincide con el reportado)"
                : ""
            }}
          </p>
          <a-table
            size="small"
            :pagination="false"
            :row-selection="rowSelection"
            :columns="columns"
            :data-source="localOptions"
          >
            <template slot="name" slot-scope="text, record">
              <a class="flex text-left">{{ text }}</a>
            </template>
            <template class="p-0" slot="count" slot-scope="text, record">
              <BaseNumber
                v-if="isRowSelected(record.id)"
                :disabled="soloPreview"
                :value="valueCount(record.id)"
                @notify-change="updateDetail(record.id, $event)"
                label="Digita una cantidad"
                ref="num_31_numero_total_solicitudes"
                class="col-start-1 col-span-2 mt-0 pt-0"
                placeholder="Ej: 123"
                :mt_disabled="true"
                :validatePositiveInteger="true"
              />
            </template>
          </a-table>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>